import { Injectable } from '@angular/core';
import { Branch, IBranch } from '../models/branch.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  public loading: boolean = false;
  public branches: Branch[] = [];
  public currentBranch?: Branch;
  public subCurrentBranch: Subject<Branch> = new Subject<Branch>();

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 
    this.getAll();
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
 /**
  * FUNCTION create
  * 
  * @param branch 
  * @returns 
  */
  async create(
    branch: IBranch
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlAPI}/companies/${environment.idCompany}/branches`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando sucursal';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(branch),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        flag = true;
        this.getAll();
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
 /**
  * FUNCTION delete
  * 
  * @param branch 
  * @returns 
  */
  async delete(
    branch: Branch
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlAPI}/companies/${environment.idCompany}/branches/${branch.idBranch}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = `Borrando sucursal ${branch.name}`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
 /**
  * FUNCTION get
  * 
  * @param idBranch 
  * @returns 
  */
  async get(
    idBranch: string
  ): Promise<Branch | undefined> {
    
    let branch: Branch | undefined;

    const urlApi = `${environment.urlAPI}/companies/${environment.idCompany}/branches/${idBranch}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Cargando sucursal';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ branch: IBranch }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ branch: IBranch }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ branch: IBranch }>
              ) => resolve(
                new ServerResponse<{ branch: IBranch }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        branch = new Branch(response.data!.branch);
        this.currentBranch = branch;
        this.subCurrentBranch.next(branch);
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return branch;

  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
 /**
  * FUNCTION getAll
  */
  async getAll() {
    this.loading = true;

    const urlApi = `${environment.urlAPI}/companies/${environment.idCompany}/branches`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ branches: IBranch[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ branches: IBranch[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ branches: IBranch[] }>
              ) => resolve(
                new ServerResponse<{ branches: IBranch[] }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        this.branches = response.data!.branches.map(
          (branch) => new Branch(branch)
        );
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
 /**
  * FUNCTION update
  * 
  * @param branch 
  * @returns 
  */
  async update(
    branch: Branch
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlAPI}/companies/${environment.idCompany}/branches/${branch.idBranch}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = `Actualizando sucursal ${branch.name}`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(branch),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

}
